import React from "react"
import theme from "theme"
import { Theme, Image, Box, Text, Link, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"about"} />
      <Helmet>
        <title>A propos de nous - Découvrez les pneus Tovirex Pride</title>
        <meta
          name={"description"}
          content={"Le voyage derrière chaque volant"}
        />
        <meta
          property={"og:title"}
          content={"A propos de nous - Découvrez les pneus Tovirex Pride"}
        />
        <meta
          property={"og:description"}
          content={"Le voyage derrière chaque volant"}
        />
        <meta
          property={"og:image"}
          content={"https://tovirexpride.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://tovirexpride.com/img/3257257.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://tovirexpride.com/img/3257257.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://tovirexpride.com/img/3257257.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://tovirexpride.com/img/3257257.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://tovirexpride.com/img/3257257.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://tovirexpride.com/img/3257257.png"}
        />
      </Helmet>
      <Components.Header />
      <Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          width="50%"
          justify-content="flex-start"
          lg-width="100%"
          align-items="flex-start"
          lg-margin="0px 0px 32px 0px"
          margin="0px 0px 0px 0px"
          padding="0px 32px 0px 0px"
          lg-padding="0px 0px 0px 0px"
          lg-justify-content="center"
        >
          <Image
            src="https://tovirexpride.com/img/4.jpg"
            object-fit="cover"
            width="100%"
            height="100%"
            border-radius="24px"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            min-height="600px"
            max-width="480px"
            sm-min-height="100vw"
          />
        </Box>
        <Box
          display="flex"
          width="50%"
          flex-direction="column"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          padding="16px 0px 16px 16px"
          justify-content="center"
        >
          <Text
            margin="0px 0px 24px 0px"
            color="--dark"
            font="--headline1"
            lg-text-align="center"
            sm-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
          >
            La route vers Tovirex Pride
          </Text>
          <Text
            margin="0px 0px 40px 0px"
            color="--greyD3"
            font="--lead"
            lg-text-align="center"
          >
            Les pneus Tovirex Pride sont nés d'une passion pour l'excellence
            automobile et d'un engagement en faveur de la sécurité routière.
            Notre parcours a commencé par une mission simple : donner à chaque
            conducteur la confiance nécessaire pour explorer de nouveaux
            horizons, en sachant que son véhicule est équipé des meilleurs pneus
            pour toutes les aventures. Au fil des ans, nous sommes passés d'un
            modeste service de pneus à un partenaire de confiance pour
            d'innombrables conducteurs, en restant toujours fidèles à notre
            mission de qualité, de fiabilité et de service.
          </Text>
          <Link
            href="/contact"
            padding="12px 24px 12px 24px"
            color="--light"
            text-decoration-line="initial"
            font="--lead"
            border-radius="8px"
            margin="0px 16px 0px 0px"
            transition="background-color 0.2s ease-in-out 0s"
            sm-margin="0px 0px 16px 0px"
            sm-text-align="center"
            background="--color-primary"
            hover-transition="background-color 0.2s ease-in-out 0s"
            hover-background="--color-primary"
          >
            Contacts
          </Link>
        </Box>
      </Section>
      <Section
        padding="64px 0"
        sm-padding="40px 0"
        font="--base"
        color="--dark"
      >
        <Override slot="SectionContent" align-items="center" />
        <Text
          font="--headline2"
          max-width="520px"
          margin="0 auto 5px auto"
          text-align="center"
        >
          Pourquoi Tovirex Pride se démarque-t-il?
        </Text>
        <Box
          margin="40px -16px -16px -16px"
          display="grid"
          flex-wrap="wrap"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="32px"
          md-grid-template-columns="repeat(2, 1fr)"
          lg-grid-gap="24px"
          md-grid-gap="16px"
          sm-grid-template-columns="1fr"
          sm-grid-gap="32px"
          width="100%"
        >
          <Box display="flex" flex-direction="column">
            <Text as="p" margin="0px 0 5px 0" font="--base" color="--grey">
              Options écologiques : Nous nous engageons en faveur du
              développement durable et proposons une sélection de pneus
              écologiques qui réduisent l'impact sur l'environnement sans
              compromettre les performances.
            </Text>
          </Box>
          <Box display="flex" flex-direction="column">
            <Text as="p" margin="0px 0 5px 0" font="--base" color="--grey">
              Solutions personnalisées : Sachant que chaque véhicule et chaque
              conducteur est unique, nous nous spécialisons dans les solutions
              de pneus personnalisées, adaptées à vos habitudes de conduite et
              aux exigences de votre véhicule.
            </Text>
          </Box>
          <Box display="flex" flex-direction="column">
            <Text as="p" margin="0px 0 5px 0" font="--base" color="--grey">
              Axé sur la communauté : Nous croyons qu'il faut rendre la
              pareille, participer aux événements locaux et soutenir les
              initiatives qui rendent nos routes plus sûres pour tout le monde.
            </Text>
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-11"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          width="50%"
          lg-width="100%"
          lg-align-items="center"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          flex-direction="column"
          align-items="flex-start"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
        >
          <Text
            sm-font="--headline3"
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            md-text-align="left"
          >
            Notre engagement pour l'excellence
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            sm-margin="0px 0px 30px 0px"
            md-text-align="left"
          >
            Au cœur de Tovirex Pride Tires se trouve notre engagement
            inébranlable à vous fournir la meilleure sélection de pneus et le
            meilleur service. Nous sommes fiers de nos : Nos installations sont
            à la pointe de la technologie : Notre atelier est équipé de la
            technologie la plus récente pour évaluer, monter et entretenir les
            pneus, ce qui garantit la précision et l'efficacité. Une équipe
            d'experts en pneus : Notre personnel bien informé et amical est à la
            pointe de la technologie et des tendances en matière de pneus, prêt
            à offrir des recommandations personnalisées.
          </Text>
        </Box>
        <Box
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
        >
          <Box
            width="100%"
            height="auto"
            padding="0px 0px 70% 0px"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            overflow-x="hidden"
            overflow-y="hidden"
            position="relative"
            transform="translateY(0px)"
          >
            <Image
              position="absolute"
              width="100%"
              top="auto"
              left={0}
              src="https://tovirexpride.com/img/5.jpg"
              object-fit="cover"
              display="block"
              right={0}
              bottom="0px"
              min-height="100%"
            />
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-10"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
        >
          <Box
            overflow-x="hidden"
            overflow-y="hidden"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 70% 0px"
            width="100%"
            height="auto"
            position="relative"
            hover-transform="translateY(-10px)"
          >
            <Image
              left={0}
              src="https://tovirexpride.com/img/6.jpg"
              object-fit="cover"
              width="100%"
              top="auto"
              right={0}
              bottom="0px"
              min-height="100%"
              position="absolute"
              display="block"
            />
          </Box>
        </Box>
        <Box
          flex-direction="column"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          lg-order="1"
          width="50%"
          align-items="flex-start"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          display="flex"
        >
          <Text
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            sm-font="--headline3"
            md-text-align="left"
          >
            Rejoignez la révolution
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
            Choisir les pneus Tovirex Pride, c'est bien plus qu'obtenir de
            nouveaux pneus, c'est faire partie d'une communauté qui valorise la
            sécurité, la qualité et l'innovation. Laissez-nous faire partie de
            votre voyage, en vous aidant à parcourir la route avec confiance et
            tranquillité d'esprit. Que vous soyez un navetteur de tous les
            jours, un aventurier tout-terrain ou quelque chose entre les deux,
            nous sommes là pour veiller à ce que votre véhicule soit prêt à vous
            emmener là où vous voulez aller.
          </Text>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
